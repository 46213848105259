import { TranslocoService, provideTransloco } from '@jsverse/transloco'

import { TranslocoHttpLoader, i18nInitializer } from './transloco-loader'

import { provideHttpClient, withFetch } from '@angular/common/http'
import { APP_INITIALIZER, ApplicationConfig } from '@angular/core'
import { provideClientHydration } from '@angular/platform-browser'
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async'
import { Router, provideRouter } from '@angular/router'

import { environment } from 'src/environments/environment'

import { routes } from './app.routes'

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideClientHydration(),
    provideAnimationsAsync(),
    provideHttpClient(withFetch()),
    {
      provide: APP_INITIALIZER,
      useFactory: i18nInitializer,
      multi: true,
      deps: [Router, TranslocoService],
    },
    provideTransloco({
      config: {
        availableLangs: environment.langList,
        defaultLang: environment.langList[0],
        fallbackLang: environment.langList[0],
        reRenderOnLangChange: true,
        prodMode: environment.production,
        missingHandler: {
          allowEmpty: true,
        },
      },
      loader: TranslocoHttpLoader,
    }),
  ],
}
