import { inject } from '@angular/core'
import { CanActivateFn, Router } from '@angular/router'

import { AuthService } from 'src/app/core/services/auth.service'

export const adminAuthGuard: CanActivateFn = async (route, state): Promise<boolean> => {
  const authService = inject(AuthService)
  const router = inject(Router)

  const redirectUrl = '/login'

  const currentRole = authService.getCurrentMockRole()

  if (currentRole === 'admin') {
    return true
  } else {
    return await router.navigate([redirectUrl], {
      queryParams: { returnUrl: state.url },
    })
  }
}
