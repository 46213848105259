import { BehaviorSubject, Observable, filter, map } from 'rxjs'
import { NavigationItem } from 'src/app/common/header/components/navigation/shared/types'

import { AsyncPipe, NgFor, NgIf } from '@angular/common'
import { ChangeDetectionStrategy, Component } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { NavigationEnd, Router, RouterLink } from '@angular/router'

import { navItems } from 'src/app/app.navigation'

import { SvgIcon } from 'src/app/shared/svg-icons/svg-icons.enum'
import { SvgIconsModule } from 'src/app/shared/svg-icons/svg-icons.module'

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [MatIconModule, SvgIconsModule, NgIf, AsyncPipe, NgFor, RouterLink],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  svgIcons = SvgIcon

  isLargeFooter$: Observable<boolean> = this.router.events.pipe(
    filter((event): event is NavigationEnd => event instanceof NavigationEnd),
    map((event: NavigationEnd) => !event.url.includes('admin') || !event.url.includes('user')),
  )

  private navItems$: BehaviorSubject<NavigationItem[]> = new BehaviorSubject<NavigationItem[]>(navItems)
  footerItems$: Observable<NavigationItem[]> = this.navItems$
    .asObservable()
    .pipe(map((items) => items.filter((item) => !!item.items?.length)))

  singleItems: Observable<NavigationItem[]> = this.navItems$
    .asObservable()
    .pipe(map((items) => items.filter((item) => !item.items?.length)))

  socialIcons: { icon: SvgIcon; url: string }[] = [
    { icon: SvgIcon.FB, url: '/' },
    { icon: SvgIcon.TWITTER, url: '/' },
    { icon: SvgIcon.INSTAGRAM, url: '/' },
    { icon: SvgIcon.TELEGRAM, url: '/' },
  ]

  constructor(private router: Router) {}
}
