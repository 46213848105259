import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'

import { AuthService } from 'src/app/core/services/auth.service'

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const redirectUrl = '/login'

    const currentRole = this.authService.getCurrentMockRole()

    if (currentRole === 'user') {
      return true
    } else {
      return await this.router.navigate([redirectUrl], {
        queryParams: { returnUrl: state.url },
      })
    }

    // const currentAuth = this.authService.currentAuth()

    // if (!currentAuth) {
    //   this.router
    //     .navigate([redirectUrl], {
    //       queryParams: { returnUrl: state.url },
    //     })
    //     .then()
    //     .catch()

    //   return false
    // }

    // const user = await firstValueFrom(this.authService.currentUser())

    // if (route.data?.['roles']) {
    //   if (!route.data['roles'].find((role: UserRole) => user?.roles.includes(role))) {
    //     redirectUrl = '/error/403'

    //     this.router.navigateByUrl(redirectUrl).then().catch()

    //     return false
    //   }
    // }

    // return true
  }
}
