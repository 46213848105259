import { Translation, TranslocoLoader, TranslocoService } from '@jsverse/transloco'
import { Observable } from 'rxjs'

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'

import { SsrService } from 'src/app/core/services/ssr.service'

import { environment } from 'src/environments/environment'

export function i18nInitializer(router: Router, transloco: TranslocoService) {
  return (): Observable<Translation> => {
    let lang = environment.langList[0]

    for (let i = 0; i < environment.langList.length; i++) {
      if (router.url.startsWith(`/${environment.langList[i]}`)) {
        lang = environment.langList[i]
      }
    }

    transloco.setActiveLang(lang)

    return transloco.load(lang)
  }
}

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(
    private http: HttpClient,
    private ssrService: SsrService,
  ) {}

  getTranslation(lang: string): Observable<Translation> {
    const baseUrl = this.ssrService.isBrowser ? environment.appUrl : environment.appUrlSsr

    return this.http.get<Translation>(`${baseUrl}/assets/i18n/${lang}.json?v=0`)
  }
}
